import { useOne } from '@aubade/core/adapters'
import { makeForm } from '@aubade/core/ui-kit'
import { booleanTransformer } from '@aubade/core/ui-kit/Inputs/RadioButtons'
import { RadioButtons } from '@aubade/core/ui-kit/Inputs/RadioButtons/RadioButtons'
import { isAdmin, isSociety, useGetUser } from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'
import type { Contact } from '@aubade/types/index'
import { cleanHydraId, toHydraId } from '@nartex/api-platform'
import { useEffect } from 'react'

import { SocietyAgencyFilter } from 'src/components/Filters'

import { AgencyFilter } from '../../../components/Filters/AgencyFilters'
import type { FormType } from '../types'

const { Input, useFormContext, useWatch } = makeForm<
  FormType,
  'isNational' | 'isAgencies' | 'agencies' | 'societies'
>()

type Props = {
  target?: 'notification' | 'publication'
}

export function TargetSection(props: Props) {
  const { role, agency } = useGetUser()
  if (isAdmin(role)) {
    return <AubadeTargetList {...props} />
  }
  if (isSociety(role)) {
    return <AgencyTargetList {...props} />
  }
  return <SingleAgencyTarget agencyId={agency?.id} {...props} />
}

function AubadeTargetList(props: Props) {
  const { target = 'publication' } = props
  const { setValue } = useFormContext()

  const translate = useTranslate()
  const isNational = useWatch('isNational')

  if (isNational === undefined) return <></>

  const label =
    target === 'publication'
      ? 'publications.fields.target'
      : 'notifications.fields.targets'

  return (
    <>
      <Input.RadioButtons<boolean>
        name="isNational"
        label={label}
        onChange={() => {
          setValue('agencies', [])
          setValue('societies', [])
        }}
        options={{
          true: translate('publications.fields.targetNational'),
          false: translate('publications.fields.targetSocieties'),
        }}
        transformer={booleanTransformer}
      />
      {!isNational && <SocietyAgencyFilter />}
    </>
  )
}

export function AgencyTargetList(props: Props) {
  const { target = 'publication' } = props
  const translate = useTranslate()
  const { id: me } = useGetUser()
  const { setValue } = useFormContext()

  const isAgencies = useWatch('isAgencies')
  const societies = useWatch('societies')

  const [actualUser] = useOne<Contact>({
    iri: toHydraId('users', me!),
  })

  const societyId = cleanHydraId(actualUser!.society!['@id'])

  useEffect(() => {
    if (isAgencies) {
      setValue('isNational', false)
      setValue('societies', [
        ...(societies ?? []),
        toHydraId('societies', societyId),
      ])
    } else {
      setValue('isNational', false)
      setValue('societies', [
        ...(societies ?? []).filter(
          (soc) => soc !== toHydraId('societies', societyId),
        ),
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, societyId])

  if (isAgencies === undefined) return <></>

  const label =
    target === 'publication'
      ? 'publications.fields.target'
      : 'notifications.fields.targets'

  return (
    <>
      <Input.RadioButtons<boolean>
        name="isAgencies"
        label={label}
        onChange={(value) => {
          if (value) {
            setValue('agencies', [])
            setValue('societies', [
              ...(societies ?? []).filter(
                (soc) => soc !== toHydraId('societies', societyId),
              ),
            ])
            setValue('isNational', false)
          } else {
            setValue('isNational', false)
            setValue('societies', [
              ...(societies ?? []).filter(
                (soc) => soc !== toHydraId('societies', societyId),
              ),
            ])
          }
        }}
        options={{
          true: translate('publications.fields.targetSociety'),
          false: translate('publications.fields.targetAgencies'),
        }}
        transformer={booleanTransformer}
      />
      {isAgencies === false && <AgencyFilter />}
    </>
  )
}

function SingleAgencyTarget(props: Props & { agencyId?: string }) {
  const { setValue } = useFormContext()

  const { agencyId, target = 'publication' } = props

  useEffect(() => {
    if (!agencyId) return
    setValue('isNational', false)
  }, [agencyId, setValue])

  if (!agencyId) return <></>

  const label =
    target === 'publication'
      ? 'publications.fields.target'
      : 'notifications.fields.targets'

  return (
    <>
      <Input.Hidden name={'agencies'} value={[agencyId]} />
      <RadioButtons
        label={label}
        value={'true'}
        disabled
        options={{
          true: 'Mon agence',
        }}
      />
    </>
  )
}
