import type { DataProvider } from '@aubade/core/adapters'

import type {
  ExtendedPublicationsRead,
  ExtendedPublicationsWrite,
} from '@aubade/types'

import type { User } from '@aubade/types/api'
import { cleanHydraId } from '@nartex/data-provider/react'

import type { SearchResponse } from '@nartex/typesense'

import { PublicationLinkEnum } from '../../../Resources/Publications/types'
import { encodePayloadApiToTypesense } from '../index'

export function PublicationsProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    // @ts-ignore
    async getOne(params) {
      const { id } = params
      const publications = await dataProvider.custom!<ExtendedPublicationsRead>(
        {
          ...params,
          method: 'get',
          url: `${id}/find`,
        },
      )
      const { data } = publications
      const { documents, author } = data
      const ids = documents.map((document) => cleanHydraId(document))

      const { data: fullDocuments } = await dataProvider.getMany!({
        ...params,
        resource: 'documents',
        ids: ids,
      })

      const { data: fullAuthor } = await dataProvider.getOne<User.Read>({
        ...params,
        resource: 'users',
        id: author,
      })

      return {
        ...publications,
        data: {
          ...decodePublications(data),
          documents: fullDocuments,
          fullAuthor,
        },
      }
    },
    // @ts-expect-error
    update(params) {
      const { id } = params
      const { agencies, societies, isNational } =
        params.variables as unknown as any // ExtendedPublicationsWrite

      return dataProvider.custom!({
        ...params,
        method: 'put',
        url: `publications/${id}/update`,
        payload: {
          ...params.variables,
          targets: undefined,
          targetSocieties: isNational ? [] : societies,
          targetAgencies: isNational ? [] : agencies,
        },
      })
    },
    async getList(params) {
      const { metaData } = params
      const payload = encodePayloadApiToTypesense(
        params,
        'publications',
        'title, content',
      )
      const searchResponse = await dataProvider.custom!<
        {
          results: [SearchResponse<any>]
        },
        any
      >({
        method: 'post',
        url: `publications/ts/search`,
        metaData,
        payload,
      })
      const result = searchResponse.data.results[0]

      const data = result.hits?.map((hit) => hit.document) ?? []
      return {
        data,
        total: result.found,
        raw: { typesense: result },
      }
    },
    create(params) {
      const { isNational, agencies, societies } = params.variables as any // ExtendedPublicationsWrite

      return dataProvider.create({
        ...params,
        // @ts-expect-error
        resource: 'publications/create',
        variables: {
          ...params.variables,
          targetSocieties: !isNational ? societies : [],
          targetAgencies: !isNational ? agencies : [],
          isNational: isNational,
        },
      })
    },
  }
}

export function PublicationsListProxy(
  dataProvider: DataProvider,
): Partial<DataProvider> {
  return {
    async getList(params) {
      const { metaData } = params
      const payload = encodePayloadApiToTypesense(
        params,
        'publications',
        'title, content',
      )
      const searchResponse = await dataProvider.custom!<
        {
          results: [SearchResponse<any>]
        },
        any
      >({
        method: 'post',
        url: `publications/ts/search`,
        metaData,
        payload,
      })
      const result = searchResponse.data.results[0]

      const data = result.hits?.map((hit) => hit.document) ?? []
      return {
        data,
        total: result.found,
        raw: { typesense: result },
      }
    },
  }
}

function decodePublications(
  data: ExtendedPublicationsRead,
): ExtendedPublicationsWrite {
  const {
    targetAgencies,
    targetSocieties,
    societyId,
    toolId,
    url,
    urlLabel,
    jobs,
  } = data

  let linkType = PublicationLinkEnum['enum./publicationlink/WITHOUT_LINK']

  if (toolId) {
    linkType = PublicationLinkEnum['enum./publicationlink/TOOL_LINK']
  }
  if (url || urlLabel) {
    linkType = PublicationLinkEnum['enum./publicationlink/EXTERNAL_LINK']
  }

  const hasJobs = Boolean(jobs && jobs.length > 0)

  return {
    ...data,
    linkType,
    agencies: targetAgencies,
    societies: targetSocieties,
    isAgencies: Boolean(societyId),
    allCategories: Boolean(!hasJobs),
  }
}
