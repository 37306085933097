import { makeForm } from '@aubade/core/ui-kit'

import type { FormType } from '../types'

const { Input, useWatch } = makeForm<FormType, 'planify'>()

export function PlanifySection() {
  const planify = useWatch('planify')

  return (
    <>
      <Input.Checkbox
        name="planify"
        label="publications.fields.planify"
        inputLabel="publications.title.planify"
      />

      {planify && (
        <Input.DateTime
          name="publishedAt"
          label="publications.fields.publishedAt"
          min
        />
      )}
    </>
  )
}
