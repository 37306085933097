import type { ExtendedNotificationWrite } from '@aubade/types'
import type { User, Notification } from '@aubade/types/api'

import type {
  DataProvider,
  ResourceDataProvider,
  UpdateResponse,
} from '@nartex/data-provider/react'
import type { SearchResponse } from '@nartex/typesense'

import { encodePayloadApiToTypesense } from '../index'

export function NotificationsProxy(
  dataProvider: DataProvider,
): Partial<ResourceDataProvider<ExtendedNotificationWrite>> {
  // @ts-expect-error
  return {
    async getList(params) {
      const { metaData } = params
      const payload = encodePayloadApiToTypesense(
        params,
        'notifications',
        'title, content',
      )
      const searchResponse = await dataProvider.custom!<
        {
          results: [SearchResponse<any>]
        },
        any
      >({
        method: 'post',
        hasPagination: false,
        url: `notifications/ts/search`,
        metaData,
        payload,
      })
      const result = searchResponse.data.results[0]

      const data = result.hits?.map((hit) => hit.document) ?? []

      const filteredDatas = data.filter(
        (notif) => !('publication.id' in notif) && notif,
      )

      return {
        data: filteredDatas,
        total: filteredDatas.length,
        raw: { typesense: result },
      }
    },
    // @ts-ignore
    async getOne(params) {
      const { id } = params
      const notification = await dataProvider.getOne<ExtendedNotificationWrite>(
        {
          ...params,
          id: `${id}`,
          resource: 'notifications',
        },
      )

      const { data } = notification
      const { author, publishedAt } = data
      const { data: fullAuthor } = await dataProvider.getOne<User.Read>({
        ...params,
        resource: 'users',
        id: author,
      })

      const fullNotification = decodeNotifications(notification.data)

      const planify = Boolean(
        publishedAt && new Date(publishedAt).getTime() > new Date().getTime(),
      )

      return {
        data: { ...fullNotification, fullAuthor: fullAuthor, planify: planify },
      }
    },
    async update(params) {
      const { id } = params
      const { isNational, agencies, societies, ...rest } =
        params.variables as ExtendedNotificationWrite

      return dataProvider.custom!<Notification.Read, ExtendedNotificationWrite>(
        {
          ...params,
          hasPagination: false,
          method: 'put',
          url: `notifications/${id}/update`,
          payload: {
            ...rest,
            isNational: isNational ?? false,
            targetSocieties: isNational ? [] : societies!,
            targetAgencies: isNational ? [] : agencies!,
          },
        },
      ) as Promise<UpdateResponse<ExtendedNotificationWrite>>
    },
    create(params) {
      const { isNational, agencies, societies } =
        params.variables as ExtendedNotificationWrite

      return dataProvider.create({
        ...params,
        // @ts-expect-error
        resource: 'notifications/create',
        variables: {
          ...params.variables,
          targets: undefined,
          targetSocieties: isNational ? [] : societies!,
          targetAgencies: isNational ? [] : agencies!,

          isNational: isNational,
        },
      })
    },
  } satisfies Partial<ResourceDataProvider<ExtendedNotificationWrite>>
}

export function decodeNotifications(
  data: ExtendedNotificationWrite,
): Partial<ExtendedNotificationWrite> {
  const { targetAgencies, targetSocieties, societyId, jobsIds } = data

  const hasJobs = Boolean(jobsIds && jobsIds.length > 0)

  return {
    ...data,
    societies: targetSocieties,
    agencies: targetAgencies,
    isAgencies: Boolean(societyId),
    allCategories: Boolean(!hasJobs),
    jobs: jobsIds ?? [],
  }
}
