import { drawerQueryParams, useQueryParamsState } from '@aubade/core/libs'
import type { FormSubmitProps } from '@aubade/core/ui-kit'
import {
  Button,
  ButtonWithConfirm,
  Form,
  FormDrawer,
  Paragraph,
  makeForm,
  useForm,
  unit,
} from '@aubade/core/ui-kit'
import { useGetUser, DrawerContent } from '@aubade/domain/components'
import { useTranslate } from '@aubade/translation'
import { HStack, VStack } from '@chakra-ui/react'
import { cleanHydraId, toHydraId } from '@nartex/api-platform'

import { Fragment, useMemo } from 'react'
import { useUrls } from 'src/App'
import { useMemoOne } from 'use-memo-one'
import { v4 as uuid } from 'uuid'

import { useNotificationRights } from '../../adapters/rights'
import { CategorySection } from '../Publications/form/CategorySection'
import { TargetSection } from '../Publications/form/TargetSection'

import { useActions } from './actions'
import { PlanifySection } from './Components/PlanifySection'
import { formatDate } from './formUtils/formatDate'
import { useFormValidation } from './formUtils/formValidators'

import type { NotificationFormType } from './types'

const { Input, useWatch, useFormContext } = makeForm<
  NotificationFormType,
  | 'targets'
  | 'planify'
  | 'isDraft'
  | 'publishedAt'
  | 'title'
  | 'content'
  | 'fullAuthor'
  | '@id'
  | 'isInternal'
>()

export function NotificationsEdit() {
  const [modalState] = useQueryParamsState(drawerQueryParams)

  const { page, id } = modalState

  const matchCreate = page === 'create'
  const matchUpdate = page === 'edit' && id
  const match = useMemo(
    () => Boolean(matchCreate || matchUpdate),
    [matchCreate, matchUpdate],
  )

  return (
    <FormDrawer isOpenProps={match}>
      <DrawerContent>
        {matchCreate && <FormCreate />}
        {matchUpdate && <FormUpdate id={id} />}
      </DrawerContent>
    </FormDrawer>
  )
}

export function FormCreate() {
  const urls = useUrls()
  const { id: me } = useGetUser()
  const newId = useMemoOne(() => {
    return uuid()
  }, [])

  const defaultPublishedAt = formatDate(new Date())

  const { saveButtonProps, ...methods } = useForm<NotificationFormType>({
    mode: 'create',
    resource: 'notifications',
    redirect() {
      return urls.aubade().notifications()
    },
    blockNavigation: false,
    hookFormOptions: {
      defaultValues: {
        newId,
        author: toHydraId('users', me),
        isDraft: false,
        planify: false,
        isNational: true,
        isAgencies: true,
        allCategories: true,
        publishedAt: defaultPublishedAt,
      },
    },
  })
  return (
    <>
      <Paragraph
        fontWeight="bold"
        size="lg"
        color="black.base"
        text="notifications.title.create"
      />
      <Form {...methods} bucket={{ type: 'Notification' }}>
        <FormContent {...saveButtonProps} newPulicationId={newId} />
      </Form>
    </>
  )
}

export function FormUpdate(props: { id: string }) {
  const { id } = props
  const urls = useUrls()

  const { saveButtonProps, ...methods } = useForm<NotificationFormType>({
    mode: 'update',
    id,
    resource: 'notifications',
    redirect() {
      return urls.aubade().notifications()
    },
    blockNavigation: false,
  })
  const isDraft = methods.getValues('isDraft')

  const fullAuthor = methods.getValues('fullAuthor')

  const { useCanUpdate } = useNotificationRights()

  const canEditNotification = useCanUpdate(fullAuthor)

  const publishedAt = methods.getValues('publishedAt')
  const canEdit =
    isDraft ||
    (canEditNotification &&
      new Date(publishedAt!).getTime() > new Date().getTime())
  const title = isDraft
    ? 'notifications.title.edit'
    : 'notifications.title.show'

  return (
    <>
      <Paragraph fontWeight="bold" size="lg" color="black.base" text={title} />
      <Form {...methods} bucket={{ type: 'Notification' }} readOnly={!canEdit}>
        <FormContent {...saveButtonProps} canEdit={canEdit} />
      </Form>
    </>
  )
}

type FormContentProps = FormSubmitProps & {
  newPulicationId?: string
  canEdit?: boolean
}

export function FormContent(props: FormContentProps) {
  const { newPulicationId, canEdit = true, ...saveButtonProps } = props
  const translate = useTranslate()

  const suite = useFormValidation()

  const [iri, isDraft, publishedAt, targets, title, content, fullAuthor] =
    useWatch([
      '@id',
      'isDraft',
      'publishedAt',
      'targets',
      'title',
      'content',
      'fullAuthor',
    ])

  const { setValue } = useFormContext()
  const saveButtonLabel =
    !iri || isDraft ? 'buttons.sendnotification' : 'buttons.save'

  const showBottonDraft = Boolean(!iri || isDraft)

  const { onDelete } = useActions(fullAuthor?.id)

  const canDelete = useNotificationRights().useCanDeleteNotif(fullAuthor?.id)

  const canDeleteNotification = Boolean(
    (canDelete && isDraft) ||
      (canDelete &&
        publishedAt &&
        new Date(publishedAt).getTime() >= Date.now()),
  )

  const canPublish = useMemo(() => {
    return suite({ targets, title, content }).isValidByGroup('publish')
  }, [suite, targets, title, content])
  const canDraft = suite({
    targets,
    title,
    content,
  }).isValidByGroup('draft')

  const { useCanCreate, useCanUpdate } = useNotificationRights()

  const canCreate = useCanCreate()
  const canUpdate = useCanUpdate(fullAuthor)

  const showButtonSave = useMemo(() => {
    if (newPulicationId) return canCreate
    return canUpdate
  }, [canCreate, canUpdate, newPulicationId])

  return (
    <Fragment>
      <Input.Text
        name="title"
        label="notifications.fields.title"
        maxLength={50}
      />
      <Input.TextArea
        name="content"
        label="notifications.fields.description"
        maxLength={100}
      />

      <VStack gap={unit('40')} alignItems={'flex-start'} width="full">
        <TargetSection target="notification" />
        <CategorySection />
        <PlanifySection />
        <HStack gap={'30px'} justifyContent={'flex-start'} width="full">
          {canEdit && showButtonSave && (
            <Button
              {...saveButtonProps}
              label={saveButtonLabel}
              variant="primary"
              isDisabled={!canPublish}
              onClick={async (evt) => {
                setValue('isDraft', false)
                if (!publishedAt || publishedAt === null) {
                  setValue('publishedAt', new Date())
                }
                await saveButtonProps?.onClick?.(evt)
              }}
            />
          )}

          {showBottonDraft && (
            <Button
              {...saveButtonProps}
              label="buttons.draft"
              variant="text"
              isDisabled={!canDraft}
              onClick={async (evt) => {
                setValue('isDraft', true)
                setValue('publishedAt', undefined)
                await saveButtonProps?.onClick?.(evt)
              }}
            />
          )}

          {iri && canDeleteNotification && (
            <ButtonWithConfirm
              dialogProps={{
                title: 'notifications.confirmTitle',
                children: translate('notifications.confirmText'),
              }}
              confirmButtonProps={{
                isDisabled: false,
                label: 'notifications.confirmButton',
                variant: 'primary',
              }}
              buttonProps={{
                label: 'buttons.delete',
                isDisabled: false,
                variant: 'text',
                isDangerous: true,
              }}
              onConfirm={async () => {
                await onDelete(cleanHydraId(iri))
              }}
            />
          )}
        </HStack>
      </VStack>
    </Fragment>
  )
}
