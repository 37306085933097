// Inspired from https://chakra-templates.dev/navigation/sidebar

import { drawerQueryParams, useQueryParamsState } from '@aubade/core/libs'

import { Button, unit, useGetDocumentUrl } from '@aubade/core/ui-kit'
import { IconExit, LogoPro } from '@aubade/design/graphics'
import {
  ProfileCard,
  isAdmin,
  useGetUser,
  SelectedAgencyList,
} from '@aubade/domain/components'
import type { User } from '@aubade/types/api'

import { useOidc } from '@axa-fr/react-oidc'
import type { BoxProps } from '@chakra-ui/react'
import {
  Flex,
  HStack,
  VStack,
  Drawer,
  DrawerContent,
  useDisclosure,
  Tooltip,
  Icon,
  Box,
} from '@chakra-ui/react'

import { toHydraId } from '@nartex/api-platform'
import { ErrorBoundary } from '@sentry/react'

import type { FallbackRender } from '@sentry/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { ReactNode, PropsWithChildren } from 'react'
import { Suspense } from 'react'
import { Outlet, Link as RouterLink } from 'react-router-dom'

import { useEnv } from '../../../../../apps/bo/src/adapters/Env'
import { usePostTokenToAPi } from '../../../../../apps/bo/src/adapters/firebase'
import { useAubadeQueryBuilder } from '../../../../../apps/bo/src/aubadeQueryBuilder/useAubadeQueryBuilder'
import { useOne } from '../../adapters'
import { SwitchInput } from '../Inputs'

import { Layout } from '.'

const DESKTOP_MENU_WIDTH = '242px'

type SiderProps = {
  sider: ReactNode
  profileDrawer: ReactNode
  menu?: ReactNode
  searchBox?: ReactNode
  header?: ReactNode
  errorBoundaryFallback?: FallbackRender
}

export function SiderLayout(props: PropsWithChildren<SiderProps>) {
  const { sider, errorBoundaryFallback, profileDrawer, children } = props
  const { isOpen, onClose } = useDisclosure()

  return (
    <Box height={'100vh'} bg="lightGrey.500">
      <SidebarContent
        onClose={onClose}
        display={{ base: 'none', md: 'flex' }}
        maxWidth={DESKTOP_MENU_WIDTH}
      >
        {sider}
        {profileDrawer}
      </SidebarContent>
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose}>
            {sider}
            {profileDrawer}
          </SidebarContent>
        </DrawerContent>
      </Drawer>
      <Box
        marginLeft={{ base: 0, md: DESKTOP_MENU_WIDTH }}
        paddingX={0}
        paddingY={0}
        margin={0}
        height="100vh"
      >
        <ErrorBoundary
          fallback={
            errorBoundaryFallback ??
            ((report) => <Layout.Error code={500} report={report} />)
          }
        >
          <Suspense fallback={<Layout.Loading />}>
            {children ?? <Outlet />}
          </Suspense>
        </ErrorBoundary>
      </Box>
    </Box>
  )
}

type SidebarProps = {
  onClose: () => void
} & BoxProps

export function SidebarContent(props: SidebarProps) {
  const { children, ...rest } = props
  const { id: me, role: userRole } = useGetUser()
  const { isAuthenticated, logout } = useOidc()

  const { removeToken } = usePostTokenToAPi(isAuthenticated)

  const [user] = useOne<User.Read>(
    { iri: toHydraId('users', me ?? '') },
    { enabled: Boolean(me) },
  )

  const queryClient = useQueryClient()
  const [_modalState, setModalState] = useQueryParamsState(drawerQueryParams)

  const aubadeQueryBuilder = useAubadeQueryBuilder()

  const { mutate: togglaAvailable } = useMutation(
    aubadeQueryBuilder.togglaAvailable(),
  )

  const getDocumentUrl = useGetDocumentUrl({
    size: { width: 100, height: 100 },
    ratio: 'crop',
  })
  const env = useEnv()
  const { BASE_PATH } = env

  if (!user) return <></>
  const { firstname, lastname, avatar, isAvailable } = user
  const switchLabel = isAvailable ? 'user.available' : 'user.unavailable'

  const pictureUrl = getDocumentUrl(avatar?.[0])

  const displayedRole = user?.userFunction?.name ?? ''

  return (
    <Flex
      transition=".2s ease"
      width="full"
      position="fixed"
      height="full"
      flexDirection="column"
      overflowX="hidden"
      overflowY={'auto'}
      backgroundColor="lightGrey.500"
      paddingBottom={unit('20')}
      {...rest}
    >
      <Flex
        paddingX={10}
        paddingY={unit('30')}
        direction="column"
        flex={1}
        gap={8}
      >
        <RouterLink to="/">
          <Icon as={LogoPro} width={unit('70')} height="auto" />
        </RouterLink>

        <VStack gap={10} marginTop={20}>
          {children}
        </VStack>
      </Flex>

      <VStack width={'100%'} gap={0} marginTop={8} alignItems="flex-start">
        {!isAdmin(userRole) && (
          <HStack
            gap={4}
            paddingY={5}
            width={'100%'}
            paddingLeft={10}
            borderTop={'1px solid'}
            borderColor="black.alpha10"
            justifyContent={'flex-start'}
          >
            <SwitchInput
              label={switchLabel}
              value={isAvailable}
              onChange={() =>
                togglaAvailable({ isAvailable: !user!.isAvailable })
              }
              variant="negative"
            />
          </HStack>
        )}
        <Tooltip label={'tooltip.profile'}>
          <HStack
            width="full"
            spacing={0}
            paddingY={5}
            paddingLeft={10}
            justifyContent={'flex-start'}
            borderY={'1px solid'}
            borderColor="black.alpha10"
            onClick={() => setModalState({ page: 'profile' })}
            cursor={'pointer'}
          >
            {isAuthenticated && (
              <ProfileCard
                name={[firstname, lastname].filter(Boolean).join(' ')}
                role={displayedRole}
                picture={pictureUrl}
              />
            )}
          </HStack>
        </Tooltip>
        <HStack
          gap={4}
          paddingY={5}
          width={'100%'}
          paddingX={10}
          borderTop={'1px solid'}
          borderColor="black.alpha10"
          justifyContent={'flex-start'}
        >
          <SelectedAgencyList />
        </HStack>
        <HStack
          width="full"
          justifyContent="flex-start"
          paddingY={unit('20')}
          paddingLeft={10}
        >
          {isAuthenticated && (
            <Button
              variant="link"
              label="menu.logout"
              leftIcon={IconExit}
              onClick={async () => {
                if (!isAdmin(userRole)) {
                  const permission = await Notification.requestPermission()
                  if (permission === 'granted') {
                    await removeToken()
                  }
                }
                queryClient.clear()
                sessionStorage.removeItem('jwt')
                sessionStorage.removeItem('agency')
                localStorage.clear()

                await logout(`${BASE_PATH}/fil`)
              }}
            />
          )}
        </HStack>
      </VStack>
    </Flex>
  )
}
